<template>
  <div class="mb-3 mt-5 pt-3">
    <ComponentOtp
      :target-sent-otp="targetSentOtp"
      :error-message="errorMessage"
      :is-start-countdown="startCountdown"
      :timestamp-expired="timestampExpired"
      :otp="otp"
      @setOtp="setOtp"
      @validateOtp="validateOtp"
      @resendOtp="resendOtp"
    />
  </div>
</template>

<script>
import ComponentOtp from '@/components/cleanui/system/Auth/RegConfOtp'
import Cookies from 'js-cookie'

export default {
  components: {
    ComponentOtp,
  },
  data () {
    return {
      otp: '',
      errorMessage: null,
      startCountdown: false,
      timestampExpired: 0,
      targetSentOtp: null,
      username: '',
      otpSendTo: '',
      type: '',
    }
  },
  created () {
    const dataResponseRequestForgotPassword = Cookies.get('dataResponseRequestForgotPassword') ? JSON.parse(Cookies.get('dataResponseRequestForgotPassword')) : {}

    if (dataResponseRequestForgotPassword) {
      this.startCountdown = true
      this.timestampExpired = dataResponseRequestForgotPassword.otp?.resend_delay
      this.targetSentOtp = dataResponseRequestForgotPassword.target
      this.username = dataResponseRequestForgotPassword.username
      this.otpSendTo = dataResponseRequestForgotPassword.otpSendTo
      this.type = dataResponseRequestForgotPassword.type
    }
  },
  methods: {
    setOtp (value) {
      this.otp = value
    },
    validateOtp () {
      this.$store.dispatch('user/VERIFYOTPFORGOTPASSWORD', {
        username: this.username,
        otp: this.otp,
      })
        .then(() => {
          Cookies.remove('dataResponseRequestForgotPassword')
          this.$router.push({ path: '/auth/forgot-password/reset-password' })
        })
        .catch((err) => {
          this.$notification.error({
            message: err?.response?.data?.message || 'OTP tidak valid',
          })
        })
    },
    resendOtp () {
      // please call resend otp based on user registered.
      // is from email or from phone, or another user getting otp
      this.$store.dispatch('user/REQUESTFORGOTPASSWORD', {
        username: this.otpSendTo,
        type: this.type,
        setup_password_url: 'https://saas-admin.sit.dev-powerbiz.asia/auth/forgot-password/reset-password',
      })
        .then(({ data }) => {
          // this.$router.push({ path: '/auth/forgot-password/otp' })
          this.$notification.success({
            message: 'Resend OTP Success',
          })
          this.startCountdown = true
          this.timestampExpired = data.otp?.resend_delay
        })
        .catch((err) => {
          this.$notification.error({
            message: err?.response?.data?.message || 'Kirim ulang OTP gagal',
          })
        })
    },
  },
}
</script>

<style>

</style>
